const crearEspacioPath = '/cms/espacio/crear';
import {_postSec} from './rest';

export {_crearEspacio};

let loggedIn;
try {
  loggedIn = JSON.parse(localStorage.getItem('vuex')).userInfo;
} catch {
  loggedIn = false;
}

const _crearEspacio = datos => {
  return new Promise(resolve => {
    _crearEspacioCall(datos, data => {
      if (data.status === 200) {
        data = {status: data.status, msg: 'Espacio creado exitosamente.'};
      }
      resolve(data);
    });
  });
};
function _crearEspacioCall(datos, callback) {
  _postSec(crearEspacioPath, datos, loggedIn, function(resultado) {
    callback(resultado);
  });
}
