var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "section content-title-group" }, [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titulo))]),
    ]),
    _c("br"),
    _c("br"),
    _c("span", { staticClass: "texto30px" }, [
      _vm._v(" Crea tu espacio aquí. "),
    ]),
    _c("div", { staticClass: "containerCreacionEspacio" }, [
      _c("label", { staticClass: "labelCreacionEspacio" }, [
        _vm._v("Nombre para URL"),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.nombreurl,
            expression: "nombreurl",
          },
        ],
        staticClass: "inputCreacionEspacio",
        attrs: {
          id: "nombreurl",
          required: "",
          placeholder: "Nombre para url",
        },
        domProps: { value: _vm.nombreurl },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.nombreurl = $event.target.value
          },
        },
      }),
      _c("label", { staticClass: "labelCreacionEspacio" }, [
        _vm._v("Nombre para mostrar"),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.nombreparamostrar,
            expression: "nombreparamostrar",
          },
        ],
        staticClass: "inputCreacionEspacio",
        attrs: { id: "nombreparamostrar", placeholder: "Nombre para mostrar" },
        domProps: { value: _vm.nombreparamostrar },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.nombreparamostrar = $event.target.value
          },
        },
      }),
      _c("label", { staticClass: "labelCreacionEspacio" }, [
        _vm._v("Privado "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.esprivado,
              expression: "esprivado",
            },
          ],
          staticClass: "inputCreacionEspacio",
          attrs: {
            type: "checkbox",
            id: "esprivado",
            checked: "",
            placeholder: "Privado",
          },
          domProps: {
            checked: Array.isArray(_vm.esprivado)
              ? _vm._i(_vm.esprivado, null) > -1
              : _vm.esprivado,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.esprivado,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.esprivado = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.esprivado = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.esprivado = $$c
              }
            },
          },
        }),
      ]),
      _c("label", { staticClass: "labelCreacionEspacio" }, [
        _vm._v("Habilitado "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.estahabilitado,
              expression: "estahabilitado",
            },
          ],
          staticClass: "inputCreacionEspacio",
          attrs: {
            type: "checkbox",
            id: "estahabilitado",
            checked: "",
            placeholder: "Habilitado",
          },
          domProps: {
            checked: Array.isArray(_vm.estahabilitado)
              ? _vm._i(_vm.estahabilitado, null) > -1
              : _vm.estahabilitado,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.estahabilitado,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.estahabilitado = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.estahabilitado = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.estahabilitado = $$c
              }
            },
          },
        }),
      ]),
      !_vm.estahabilitado
        ? _c("label", { staticClass: "labelCreacionEspacio" }, [
            _vm._v(" Fecha de habilitación "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.fecha_habilitacion,
                  expression: "fecha_habilitacion",
                },
              ],
              staticClass: "inputCreacionEspacio",
              attrs: {
                id: "fechahabilitacion",
                type: "date",
                placeholder: "Fecha de habilitación",
              },
              domProps: { value: _vm.fecha_habilitacion },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.fecha_habilitacion = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _c("label", { staticClass: "labelCreacionEspacio" }, [
        _vm._v("Incluir página "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.tienepagina,
              expression: "tienepagina",
            },
          ],
          staticClass: "inputCreacionEspacio",
          attrs: { type: "checkbox", id: "tienepagina" },
          domProps: {
            checked: Array.isArray(_vm.tienepagina)
              ? _vm._i(_vm.tienepagina, null) > -1
              : _vm.tienepagina,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.tienepagina,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.tienepagina = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.tienepagina = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.tienepagina = $$c
              }
            },
          },
        }),
      ]),
      _vm.tienepagina
        ? _c("label", { staticClass: "labelCreacionEspacio" }, [
            _vm._v(" Tipo de página "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tipopagina,
                    expression: "tipopagina",
                  },
                ],
                attrs: { name: "tipopagina" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.tipopagina = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "texto-normal" } }, [
                  _vm._v("Texto"),
                ]),
                _c(
                  "option",
                  { attrs: { value: "texto-agrupado-en-secciones" } },
                  [_vm._v("Texto agrupado en secciones")]
                ),
              ]
            ),
            _c("br"),
            _vm._v(" Contenido "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pagina,
                  expression: "pagina",
                },
              ],
              staticClass: "inputCreacionEspacio",
              attrs: { id: "pagina" },
              domProps: { value: _vm.pagina },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.pagina = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.error
        ? _c("p", { staticClass: "error" }, [
            _vm._v("No s epudo realizar la operación."),
          ])
        : _vm._e(),
      _c("input", {
        staticClass: "inputCreacionEspacio",
        attrs: { type: "submit", value: "Crear espacio" },
        on: { click: _vm.crearEspacio },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }