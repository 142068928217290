<template>
  <div id="app">
    <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
    <!-- eslint-disable-next-line-->
    </br></br>
    <span class="texto30px">
      Crea tu espacio aquí.
      <!--<span class="flecha-doble-invertida texto30px resaltador-celeste"></span
    >--></span>
    <!--<FilterBar :mensajeFiltro="mensajeFiltro" />-->
    <div class="containerCreacionEspacio" >
        <label class="labelCreacionEspacio">Nombre para URL</label>
            <input
              v-model="nombreurl"
              class="inputCreacionEspacio"
              id="nombreurl"
              required
              placeholder="Nombre para url"
            />
            <label class="labelCreacionEspacio">Nombre para mostrar</label>
            <input
              v-model="nombreparamostrar"
              class="inputCreacionEspacio"
              id="nombreparamostrar"
              placeholder="Nombre para mostrar"
            />
            <label class="labelCreacionEspacio">Privado
            <input
              v-model="esprivado"
              class="inputCreacionEspacio"
              type="checkbox"
              id="esprivado"
              checked
              placeholder="Privado"
            /></label>
            <label class="labelCreacionEspacio">Habilitado
            <input
              v-model="estahabilitado"
              class="inputCreacionEspacio"
              type="checkbox"
              id="estahabilitado"
              checked
              placeholder="Habilitado"
            />
            </label>
            
            <label  v-if="!estahabilitado"  class="labelCreacionEspacio">
                Fecha de habilitación
            <input
                v-model="fecha_habilitacion"
                class="inputCreacionEspacio"
                id="fechahabilitacion"
                type= "date"
                placeholder="Fecha de habilitación"
                />
            </label>

            <label class="labelCreacionEspacio">Incluir página
            <input
              v-model="tienepagina"
              class="inputCreacionEspacio"
              type="checkbox"
              id="tienepagina"
            />
            </label>

            <label v-if="tienepagina"  class="labelCreacionEspacio">
                Tipo de página
                <select v-model="tipopagina" name="tipopagina">
                    <option value="texto-normal">Texto</option>
                    <option value="texto-agrupado-en-secciones">Texto agrupado en secciones</option>
                </select>
            <!-- eslint-disable-next-line-->
            </br>
            Contenido
            <textarea
              v-model="pagina"
              class="inputCreacionEspacio"
              id="pagina"
            >
            </textarea>
            </label>
            <p v-if="error" class="error">No s epudo realizar la operación.</p>
            <input type="submit" class="inputCreacionEspacio" @click="crearEspacio" value="Crear espacio" />
          </div>
  </div>
</template>

<script>

import {_crearEspacio} from './scripts/espacios';
//Vue.component('filter-bar', FilterBar);

export default {
  name: 'app',
  components: {
    //FilterBar,
    //MapModal,
  },
  data() {
    return {
      titulo: 'Crear nuevo espacio',
      estahabilitado: true,
      nombreurl: '',
      nombreparamostrar: '',
      esprivado: true,
      error: false,
      tienepagina: false,
      tipopagina: '',
      pagina: '',
      fecha_habilitacion:'',
    };
  },
  created() {},
  methods: {
      crearEspacio: function(){
          let pagina = this.pagina;
          pagina = pagina.replaceAll('"','\\"');
          let privado = ''+this.esprivado;
          let datos = {
              habilitado: this.estahabilitado,
              fecha_habilitacion: this.fecha_habilitacion,
              lastcount: 0,
              privado: privado,
              pagina: pagina,
              titulo: this.nombreurl,
              nombreparamostrar: this.nombreparamostrar,
              creado_por:1,
          };
          _crearEspacio(datos) .then(resultado => {
          //console.log(resultado);
          if (resultado.status === 200) {
            
            console.log('Resultado: ' + JSON.stringify(resultado));
          } else {
            this.error = true;
            console.log('ERROR: -> ' + JSON.stringify(resultado));
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.error = true;
          //console.log('ERROR: -> ' + JSON.stringify(resultado));
        });
      },
    cargarModalElemento: function(id, nombreModal) {
      id = id.replace('#', '');
      this.informacion.filter(function(elem) {
        if (elem.element_data_id == id) {
          document.getElementById('textoModal').innerHTML =
            elem.element_data_content;
          document.getElementById('exampleModalLabel').innerText = nombreModal;
          // eslint-disable-next-line no-undef
          $('#exampleModal').modal({backdrop: false});
        }
      });
    },
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.labelCreacionEspacio{
     float: left;
     width:100%;
     text-align:left;
}

.inputCreacionEspacio, .selectCreacionEspacio {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputCreacionEspacio[type=submit] {
  width: 100%;
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.inputCreacionEspacio[type=checkbox] {
  width: 10px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
}

.inputCreacionEspacio[type=submit]:hover {
  background-color: #45a049;
}

.containerCreacionEspacio {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
</style>